/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';

const LinkGeneric = ({ iconUrl, text, route, history }) => (
  <li className="sidebar__list__item">
    <Tappable
      component="a"
      className="sidebar__list__item__link"
      onTap={() => {
        history.push(route || '#');
      }}
    >
      <img src={`/img/${iconUrl}`} alt={text} />
      <p>{text}</p>
    </Tappable>
  </li>
);

export default withRouter(LinkGeneric);
