import React from 'react';
import Card from '../../../Shared/Card';
import { Link } from 'react-router-dom';

const CardGroup = ({ title, type, content }) => (
  <>
    <div className="assembly__grid__list">
      <div className="d-flex align-items-center justify-content-between mb-26">
        <p className="text__fs-18 mb-0">
          <Link to={`/assemblies/theme/${type}`} className="text__white">
            {title}
          </Link>
        </p>
        <Link
          to={`/assemblies/theme/${type}`}
          className="text__white text__fs-10"
        >
          SEE ALL
        </Link>
      </div>
      {content?.map(({ id, title: assemblyPlan, description, thumbnail }) => (
        <Card
          id={id}
          title={assemblyPlan}
          description={description}
          key={id}
          thumbnail={thumbnail}
        />
      ))}
    </div>
  </>
);

export default CardGroup;
