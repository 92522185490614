/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { returnNumberRange } from '../../../utils/helpers';

const FilterSection = () => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const { theme } = useParams();

  const filterAssemblies = (data) => {
    if (data.theme !== 'all') {
      return history.push(
        `/assemblies/theme/${data.theme.toLowerCase()}/${data.min_age}-${
          data.max_age
        }`
      );
    }
    return history.push(
      `/assemblies/age-range/${data.min_age}-${data.max_age}`
    );
  };

  const handleClearFilters = () => history.push(`/assembly-plans`);

  return (
    <div className="main__container bg__blue-nile mt-0">
      <div className="assembly__find">
        <h2 className="assembly__find__title">Find an assembly plan</h2>
        <div className="assembly__find__range">
          <label htmlFor="">Select age range</label>
          <div className="assembly__find__range__selectors">
            <select
              name="min_age"
              className="filter__select-lg"
              ref={register}
              defaultValue="5"
            >
              {returnNumberRange(3, 13).map((num) => (
                <option value={num} key={num}>
                  {num}
                </option>
              ))}
            </select>
            <p>to</p>
            <select
              name="max_age"
              id=""
              className="filter__select-lg"
              ref={register}
              defaultValue="11"
            >
              {returnNumberRange(4, 14).map((num) => (
                <option value={num} key={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="assembly__find__selection">
          <label htmlFor="">Select theme</label>
          <div className="assembly__find__selection__selectors">
            <select
              name="theme"
              id=""
              className="filter__select-lg"
              ref={register}
              defaultValue={theme?.toUpperCase() || 'all'}
            >
              <option value="all">All</option>
              <option value="ANIMATED">Animated</option>
              <option value="CHRISTMAS">Christmas</option>
              <option value="HARVEST">Harvest</option>
              <option value="SUMMER">Summer</option>
              <option value="AUTUMN">Autumn</option>
              <option value="SPRING">Spring</option>
              <option value="EASTER">Easter</option>
              <option value="WINTER">Winter</option>
            </select>
            <a
              href=""
              className="button button__form button__filter button__turquoise"
              onClick={handleSubmit(filterAssemblies)}
            >
              GO
            </a>
            <button
              className="btn btn-link ml-3 text-white d-md-block"
              onClick={() => {
                handleClearFilters();
              }}
            >
              Clear all filters
            </button>{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
