import React from 'react';
import { Link } from 'react-router-dom';
import { shortenLongDescription } from '../../../../utils/helpers';
import LikeAndAddIcons from '../../../Shared/LikeAddIcons';

const Card = ({ title, description, id, thumbnail, type }) => (
  <div className="assembly__card theme-page__card">
    <a href="" className="assembly__card__thumbnail">
      <img src={thumbnail || '/img/assembly-thumbnail.png'} alt="" />
    </a>
    <div>
      <Link to={`/assembly/${id}`} className="assembly__card__title">
        {title}
      </Link>
      <Link
        to={`/assembly/${id}`}
        className="assembly__card__text theme-page__desc"
      >
        {shortenLongDescription(description, 115)}
      </Link>
    </div>
    <div className="assembly__card__cta">
      <LikeAndAddIcons
        headerText="Options"
        darkColor
        modalId="resourceActions"
        favourite
        addToCollection
        itemType="ASSEMBLY"
        id={id}
        type={type}
      />
    </div>
  </div>
);

export default Card;
